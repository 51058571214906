<template>
  <div class="AccessVisualizationClientMissionProfile">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow class="mb-3">
      <CCol>
        <h1><CBadge color="primary"><CIcon name="cil-center-focus" size="lg"/>  Visualisation - Que voit mon client ?</CBadge> </h1>
        <h1 class="text-dark"> {{mission.client.name}} - {{mission.name}} </h1>
      </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CRow>
          <CCol>
            <span> Référent de la mission : </span>
            <strong> {{ mission.referent.full_name }} </strong>
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol sm="6">
            Mission facturée :
            <strong v-if="mission.is_billed"> Oui </strong>
            <strong v-else> Non </strong>
          </CCol>
          <CCol v-if="mission.is_billed" sm="6">
            <span> Type de facturation : </span>
            <strong v-if="mission.is_honoraires"> Honoraires</strong>
            <strong v-if="mission.is_honoraires_resultats"> Honoraires de résultats</strong>
            <strong v-if="mission.is_regie_plafonnee"> Régie plafonnée </strong>
            <strong v-if="mission.is_forfait"> Forfait </strong>
            <strong v-if="mission.is_aj"> Aide juridictionnelle </strong>
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol sm="6">
            <span> Date de début de la mission : </span>
            <strong>{{$dayjs(mission.starting_date).format('DD/MM/YYYY')}} </strong>
          </CCol>
          <CCol sm="6">
            <span> Date de fin de mission : </span>
            <strong v-if="mission.ending_date"> {{$dayjs(mission.ending_date).format('DD/MM/YYYY')}}</strong>
            <strong v-else> Non renseignée </strong>
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol>
            <span> Description : </span> <em> {{mission.description}}</em>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>📃 Demandes de documents</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="mt-1 cursor-table"
          hover
          :items="documentAskingsRender"
          :fields="documentAskingsRenderFieds"
          @row-clicked="goToDocumentAskingPage"
        >
        <!--@row-clicked="goToFacturePage"-->
          <template #state="{item}">
            <td>
              {{clientDocumentAskingsStateRender(item.state)}}
            </td>
          </template>

          <template #description="{item}">
            <td>
              <small>{{item.description}}</small>
            </td>
          </template>

          <template #no-items-view>
            <CRow>
              <CCol class="text-center">
                Aucune demande de documents réalisée
              </CCol>
            </CRow>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>


    <CCard>
      <CCardHeader>
        <strong>🧑 Collaborateurs associés</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="mt-1"
          :items="missionCollaborateursRender"
          :fields="missionCollaborateursRenderFields"
        >
        <template #phone_number="{item}">
          <td v-if="item.phone_number">
            +{{returnPhoneDial(item.phone_country_code)}} {{item.phone_number}}
          </td>
          <td v-else>
            <em>Non renseigné</em>
          </td>
        </template>
        </CDataTable>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>💵 Factures associées à la mission</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="mt-1 cursor-table"
          hover
          :items="facturesRender"
          :fields="facturesRenderFieds"
          @row-clicked="goToFacturePage"
        >
        <template #date_facturation="{item}">
          <td>
            {{$dayjs(item.date_facturation).format('DD/MM/YYYY')}}
          </td>
        </template>
        <template #montant_total_ht="{item}">
          <td>
            {{parseFloat(item.montant_total_ht).toFixed(2)}} €
          </td>
        </template>
        <template #montant_total_ttc="{item}">
          <td>
            {{parseFloat(item.montant_total_ttc).toFixed(2)}} €
          </td>
        </template>
        <template #montant_total_paid="{item}">
          <td>
            {{parseFloat(item.montant_total_paid).toFixed(2)}} €
          </td>
        </template>

        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucune facture associée
            </CCol>
          </CRow>
        </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { APIManagerConnected } from '@/api/APIManagerConnected'
import { APIUserConnected } from '@/api/APIUserConnected'

import Loading from 'vue-loading-overlay';
import renderMixins from '@/mixins/renderMixins'

import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()
const apiUserConnected = new APIUserConnected()


export default {
  name: 'AccessVisualizationClientMissionProfile',
  components: {
    Loading,
  },
  mixins: [
    renderMixins,
  ],
  data: function () {
    return {

      // ------------- MISSION -----------
      mission: {
        id: 0,
        name: '',
        client: '',
        type: [],
        state: '',
        montant_ht: '',
        starting_date: '',
        ending_date: '',
        description: '',
        is_active: '',
        created_at: '',

        contact: {
          id: 0,
          first_name: '',
          last_name: '',
          full_name: '',
        },
        referent: {
          id: 0,
          full_name: '',
          user: {
            id: 0,
            first_name: '',
            last_name: '',
            username: ''
          }
        },
        collaborateurs: []
      },
      isMissionLoading: false,
      missionCollaborateursRender: [],
      missionCollaborateursRenderFields: [
        { key: "full_name", label: "Collaborateur", tdClass: 'ui-helper-center', sortable: true},
        { key: "email", label: "Email", tdClass: 'ui-helper-center'},
        { key: "phone_number", label: "Numéro de téléphone", tdClass: 'ui-helper-center'},
      ],

      // --------------- FACTURES --------------
      isAllFacturesLoading: false,
      factures: [],
      facturesRender: [],
      facturesRenderFieds: [
        { key: "facture_number", label: "#", tdClass: 'ui-helper-center', sortable: true},
        { key: "date_facturation", label: "Date de facturation", tdClass: 'ui-helper-center'},
        { key: "montant_total_ht", label: "Montant total HT", tdClass: 'ui-helper-center'},
        { key: "montant_total_ttc", label: "Montant total TTC", tdClass: 'ui-helper-center'},
        { key: "montant_total_paid", label: "Montant payé", tdClass: 'ui-helper-center'},
      ],

      // ------------- DOCUMENT ASKING --------------
      isAllDocumentAskingsLoading: false,
      documentAskings: [],
      documentAskingsRender: [],
      documentAskingsRenderFieds: [
        { key: "name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "state", label: "Etat", tdClass: 'ui-helper-center', sortable: true},
        { key: "description", label: "Description", tdClass: 'ui-helper-center'},
      ],


    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isMissionLoading || this.isAllFacturesLoading || this.isAllDocumentAskingsLoading) {
        return true
      }
      return false
    },
  },

  created: function() {
    this.$dayjs.locale('fr')
    this.getMission()
    this.getAllMissionFactures()
    this.getAllMissionDocumentAskings()
  },

  watch: {
    mission: function (newMission) {
      var missionCollabs = newMission.collaborateurs
      if (missionCollabs.length == 0) {
        this.missionCollaborateursRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < missionCollabs.length; i++) {
          final_array.push(
            {
              'id': missionCollabs[i].id,
              'full_name': missionCollabs[i].full_name,
              'email': missionCollabs[i].user.username,
              'phone_number': missionCollabs[i].phone_number,
              'phone_country_code': missionCollabs[i].phone_country_code,
            }
          )
        }
        this.missionCollaborateursRender = final_array
      }
    },

    factures: function (newFactures) {
      if (newFactures.length == 0) {
        this.facturesRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newFactures.length; i++) {
          final_array.push(
            {
              'id': newFactures[i].id,
              'facture_number': newFactures[i].facture_number,
              'date_facturation': newFactures[i].date_facturation,
              'montant_total_ht': newFactures[i].montant_total_ht,
              'montant_total_ttc': newFactures[i].montant_total_ttc,
              'montant_total_paid': newFactures[i].montant_total_paid,
            }
          )
        }
        this.facturesRender = final_array
      }
    },

    documentAskings: function (newDocumentAskings) {
      if (newDocumentAskings.length == 0) {
        this.documentAskingsRender = []
      }
      else {
        this.documentAskingsRender = []

        for (var i = 0; i < newDocumentAskings.length; i++) {
          var temp_data = {
            'id': newDocumentAskings[i].id,
            'name': newDocumentAskings[i].name,
            'state': newDocumentAskings[i].state,
            'description': newDocumentAskings[i].description,
          }
          if (newDocumentAskings[i].state == 'VALIDATED') {
            temp_data['_classes'] = 'table-success'
          }
          else if (newDocumentAskings[i].state == 'REFUSED') {
            temp_data['_classes'] = 'table-danger'
          }

          this.documentAskingsRender.push(temp_data)
        }
      }
    },
  },

  methods: {
    // ------------- Getters --------------
    getMission () {
      this.isMissionLoading = true
      apiUserConnected.getMission(this.token, this.$route.params.id)
      .then((result) => {
        this.mission = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionLoading = false
      })
    },
    getAllMissionFactures () {
      this.isAllFacturesLoading = true
      apiManagerConnected.getAllMissionFactures(this.token, this.$route.params.id)
      .then((result) => {
        this.factures = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllFacturesLoading = false
      })
    },

    getAllMissionDocumentAskings () {
      this.isAllDocumentAskingsLoading = true
      apiUserConnected.getAllMissionDocumentAskings(this.token, this.$route.params.id)
      .then((result) => {
        this.documentAskings = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllDocumentAskingsLoading = false
      })
    },

    // --------------- ROUTER FUNCTIONS -----------------------------------
    goToFacturePage(item) {
      this.$router.push('/visualisation/access-client/facture/' + item.id)
    },
    goToDocumentAskingPage(item) {
      this.$router.push('/visualisation/access-client/documents/' + item.id)
    }


  }
}
</script>
